html, body{
  height: 100%;
  font-size: 16px;
  line-height: 20px;
  font-family: "Rubik-Regular" !important;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

body {
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

.main {
  flex: 1;
  background-color: #f9f9f9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar-expand-lg .navbar-nav .dropdown-menu  {
  right: 0;
  left: auto;
}

/* override */

.btn{
  padding: 0.5rem 2rem !important;
  border-radius: 20px !important;
}

.btn-tickets{
  padding: 0.1rem 1rem !important;
  border-radius: 40px !important;
  margin-right: 10px;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 600 !important;
}
*:focus {
  outline: none !important;
  box-shadow: none !important;
}
#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.container{
  flex: 1 1 0% !important;
}
