$color-primary: #58dd00;
$color-secondary: #000;
$color-default: #848080;
$color-dark-grey: #212529;

// Export SCSS
:root {
    --color-primary: #{$color-primary};
    --color-secondary: #{$color-secondary};
    
    --color-main-header-bg: #{$color-dark-grey};
}

// Export (JS)
:export {
    primary: $color-primary;
    secondary: $color-secondary;
    default: $color-default;
}