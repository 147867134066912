.banner-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.banner{
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}
.logo-banner{
    position: absolute;
    z-index: 999;
    width: 18rem;
}