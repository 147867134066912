.stepper{
    background: #f9f9f9;
}
.step-opacity{
    opacity: 0.5;
}
.final-stepper{
    background-color: rgb(249, 249, 249);
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.custom-content-spinner{
    display: flex;
    width: 100%;
    min-height: 200px;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.1);
}
.need-login{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.payment-options-content span{
    padding-left: 8px;
}
.payment-options-content p{
    padding-left: 8px;
    font-size: .8em;
    opacity: 0.8;
    margin: 0;
}