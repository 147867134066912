.carousel img{
    width: 100%;
    max-height: 400px;
    object-fit: contain;
}
.carousel-item{
    background-color: black;
}
.carousel-item button{
    padding: 0.3rem 8rem !important;
}
@media (max-width: 1200px)
{
    .carousel-item button{
        padding: 0.3rem 3rem !important;
    }
}