.signup-container{
    min-height: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
input[type="file"] {
    display: none;
}
.custom-input-file-label{
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-input-file-label img{
    background: black;
    opacity: 0.7;
}

.custom-input-file-label svg{
    position: absolute;
}