.header-container {
  position: relative;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(../../assets/imgs/bg-home.jpeg);
  display: flex;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
}
.header-logo {
  width: 300px;
  height: 300px;
  object-fit: cover;
  position: absolute;
  z-index: 9;
  top: 390px;
}
.header-logo-container {
  position: relative;
}
.header-logo-container img {
  position: absolute;
  top: -100px;
}

@media (max-width: 1200px) {
  .header-logo-container {
    width: 100%;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-logo {
    width: 300px;
    height: 300px;
    object-fit: cover;
    z-index: 9;
    top: 320px;
  }
  .card-margin {
    margin-top: 40% !important;
  }
}
.card {
  margin-top: 16px;
}
.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remote-html-string p {
  white-space: normal;
}
